body {
    margin: 0;
    font-family: "Quicksand", Roboto, Helvetica Neue, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Quicksand", Roboto, Helvetica Neue, Arial, sans-serif;
}

/* Remove inner shadow from inputs on mobile iOS */
textarea,
input[type="text"] {
    -webkit-appearance: none;
}
/* Set background color as #FFF to fix scrolling issue in IOS devices */
@media only screen and (max-width: 600px) {
    body {
        background-color: #fff !important;
    }
}
